.gallery_section {
  background-color: var(--body-color);
  padding: 8rem 0;
}

.gallery_container > div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.gallery_img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/*
.gallery_container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 10px;
  grid-auto-rows: 15rem;
  grid-auto-flow: dense;
}
*/

.gallery_container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

.gallery_container .wide {
  grid-column: span 2;
}

.gallery_container .tall {
  grid-row: span 2;
}

.gallery_container .big {
  grid-column: span 2;
  grid-row: span 2;
}

@media screen and (max-width: 768px) {
  .gallery_container {
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    grid-auto-rows: auto;
    grid-auto-flow: 15rem;
  }

  .gallery_container .wide,
  .gallery_container .tall,
  .gallery_container .big {
    grid-column: span 1;
    grid-row: span 1;
  }
}

@media screen and (max-width: 576px) {
  .gallery_container {
    grid-template-columns: repeat(2, 1fr);
  }
}
