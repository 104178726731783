.faq {
  background-color: var(--body-color);
  padding: 8rem 0;
}
.faq_container {
  grid-template-columns: repeat(1, 1fr);
  place-items: center;
}

.faq_box {
  width: 30rem;
  margin-bottom: 2rem;
}

.faq_line {
  width: 100%;
  height: 1px;
  background-color: var(--text-color-dark);
  margin-top: var(--mb-0-75);
  margin-bottom: var(--mb-0-75);
}

.question {
  text-align: center;
}

.answer {
  text-align: center;
}

@media screen and (max-width: 768px) {
  .faq_box {
    width: 25rem;
  }
}

@media screen and (max-width: 576px) {
  .faq_box {
    width: 100%;
  }
}
