/* -- GOOGLE FONTS  -- */
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600&display=swap");

/* =============== VARIABLES CSS =============== */
:root {
  --header-height: 3rem;

  --fade-animation-delay: 100;
  --fade-animation-duration: 1000;

  /* ========== Colors ========== */
  --light-green-color: #d6e2d9;
  --highlight-color: #4a7060;
  --border-color: rgb(45, 45, 45);
  --title-color: #d6e2d9;
  --title-color-dark: #1c232b;
  --text-color: #d6e2d9;
  --text-color-dark: #1c232b;
  --body-color: #334445;
  --container-color: #13312a;

  /* ========== Font and typography ========== */
  font-family: "Playfair Display", serif;

  /* .5rem = 8px | 1rem = 16px ... */
  --big-font-size: 3.5rem;
  --h1-font-size: 2.25rem;
  --h2-font-size: 1.5rem;
  --h3-font-size: 1.25rem;
  --normal-font-size: 1rem;
  --small-font-size: 0.875rem;
  --smaller-font-size: 0.813rem;
  --tiny-font-size: 0.625rem;

  /* ========== Font weight ========== */
  --font-normal: 400;
  --font-medium: 500;
  --font-semi-bold: 600;

  /* ========== Margenes Button ========== */
  --mb-0-25: 0.25rem;
  --mb-0-5: 0.5rem;
  --mb-0-75: 0.75rem;
  --mb-1: 1rem;
  --mb-1-5: 1.5rem;
  --mb-2: 2rem;
  --mb-2-5: 2.5rem;
  --mb-3: 3rem;

  /* ========== z index ========== */
  --z-tooltip: 10;
  --z-fixed: 100;
  --z-modal: 1000;
}

/* Responsive typography */
@media screen and (max-width: 992px) {
  :root {
    --big-font-size: 2.75rem;
    --h1-font-size: 1.5rem;
    --h2-font-size: 1.25rem;
    --h3-font-size: 1rem;
    --normal-font-size: 0.938rem;
    --small-font-size: 0.813rem;
    --smaller-font-size: 0.75rem;
  }
}

/* =============== BASE =============== */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: auto;
}

body,
button,
input,
textarea {
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
}

body {
  background-color: var(--body-color);
  color: var(--text-color);
  overscroll-behavior: auto;
  overflow-x: hidden;
}

h1,
h2,
h3 {
  color: var(--title-color);
  font-weight: var(--font-medium);
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

button {
  cursor: pointer;
  border: none;
  outline: none;
}

img {
  max-width: 100%;
  height: auto;
}

/* =============== REUSABLE CSS CLASSES =============== */

.section {
  padding: 4rem 0 4rem;
  display: grid;
  place-items: center;
}

.section_title {
  font-size: var(--big-font-size);
  font-weight: var(--font-normal);
  color: var(--title-color);
}

.section_subtitle {
  display: block;
  font-size: var(--h3-font-size);
  font-weight: var(---font-normal);
  margin-bottom: 4rem;
}

.section_title,
.section_subtitle {
  text-align: center;
}

/* =============== LAYOUT =============== */
.container {
  max-width: 968px;
  margin-left: auto;
  margin-right: auto;
}

.grid {
  display: grid;
  gap: 1.5rem;
}

/* =============== BUTTONS =============== */
.button {
  display: inline-block;
  background: linear-gradient(
    295deg,
    transparent 45%,
    var(--highlight-color) 55%
  );
  background-size: 235% 110%;
  background-position: 100%;
  border: 1px solid var(--highlight-color);
  color: var(--highlight-color);
  padding: 1.25rem 3rem;
  max-width: 300px;
  max-height: 100px;
  border-radius: 20px 0px 20px 0px / 20px 0px 20px 0px;
  border-radius: 0.75rem;
  font-weight: var(--font-medium);
  transition: 0.3s ease-in-out;
}

.button:hover {
  color: var(--body-color);
  background-position: 0%;
}

.button_icon {
  margin-left: var(--mb-0-5);
}

.button-flex {
  display: inline-flex;
  align-items: center;
}

/* =============== BREAKPOINTS =============== */
/* For large devices */
@media screen and (max-width: 992px) {
  .container {
    margin-left: var(--mb-1-5);
    margin-right: var(--mb-1-5);
  }

  .button {
    padding: 1rem 2.25rem;
    border-radius: 25px 0px 25px 0px / 25px 0px 25px 0px;
  }

  .button_icon {
    width: 22px;
    height: 22px;
  }
}

/* For medium devices */
@media screen and (max-width: 768px) {
  body {
    margin: 0 0 var(--header-height) 0;
  }

  .section_subtitle {
    margin-bottom: var(--mb-3);
  }
}

@media screen and (max-width: 576px) {
}

/* For small devices */
@media screen and (max-width: 350px) {
  :root {
    --big-font-size: 2.25rem;
  }
  .container {
    margin-left: var(--mb-1);
    margin-right: var(--mb-1);
  }
}
