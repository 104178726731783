.hero {
  position: relative;
  min-height: 100vh;
}

.hero_bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 110%;
  z-index: -1;
}

.img_overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgb(44, 113, 246);
  background: radial-gradient(
    circle,
    rgba(44, 113, 246, 0) 0%,
    rgba(0, 0, 0, 0.5) 100%
  );
}

.img {
  position: absolute;
  background: url(../../assets//hero_img.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
}

.hero_title {
  font-size: var(--h3-font-size);
  font-weight: var(--font-normal);
}

.hero_subtitle {
  font-size: var(--big-font-size);
  font-weight: var(--font-normal);
  width: 100%;
  justify-self: center;
}

.logo {
  position: absolute;
  background: url(../../assets//logo.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 0;
  bottom: calc(-4.25rem * 1.2);
  aspect-ratio: 1/1.11;
  width: 8.5rem;
  z-index: 1;
}

@media screen and (max-width: 992px) {
  .logo {
    bottom: -3rem;
    width: 6rem;
  }
}

@media screen and (max-width: 768px) {
}

@media screen and (max-width: 350px) {
}
