.header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--z-fixed);
  transition: 0.3s;
}

.stycky-hidden {
  top: calc((var(--header-height) + 1.5rem + 1px) * -1);
}

.scroll-header {
  background-color: var(--body-color);
}

.header_line {
  height: 1px;
  width: 100%;
  background-color: var(--text-color);
  opacity: 0;
  transition: 0.3s;
}

.scroll-header_line {
  opacity: 1;
}

.nav {
  height: calc(var(--header-height) + 1.5rem);
  display: grid;
  grid-template-columns: 1fr max-content 1fr;
  grid-template-rows: calc(var(--header-height) + 1.5rem);
  justify-items: center;
  align-items: center;
  column-gap: 1rem;
}

.nav_list {
  display: flex;
  column-gap: 2rem;
}

.nav_link {
  font-size: var(--medium-font-size);
  color: var(--title-color);
  font-weight: var(---font-medium);
  transition: 0.3s;
  background: linear-gradient(295deg, transparent 45%, var(--text-color) 55%);
  background-size: 250% 110%;
  background-position: 100%;
  max-width: 300px;
  max-height: 100px;
  padding: 0.25rem 0.5rem;
}

.nav_link:hover {
  color: var(--text-color-dark);
  background-position: 0%;
}

.escape_hatch {
  justify-self: start;
  position: relative;
  width: 3rem;
  height: 3rem;
  border-radius: 10rem;
  overflow: hidden;
  z-index: 1000;
}

.nav_logo {
  position: absolute;
  background: url(../../assets//logo.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
}

.nav_logo_overlay {
  position: absolute;
  background-color: black;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: 0.3s;
}

.escape_hatch:hover .nav_logo_overlay {
  opacity: 0.3;
  cursor: pointer;
}

.nav_cta {
  justify-self: end;
  font-size: var(--small-font-size);
  color: var(--title-color);
  font-weight: var(---font-medium);
  background-color: #6ca48c;
  padding: 0.5rem 1rem;
  border-radius: 5rem;
  z-index: 1000;
  transition: 0.3s;
}

.nav_cta:hover {
  background-color: var(--highlight-color);
  cursor: pointer;
}

.pc_cta {
  display: inherit;
}

.mobile_cta {
  display: none;
  text-align: left;
}

.hamburger_button {
  display: none;
  justify-content: center;
  align-items: center;
  width: 1.75rem;
  height: 1.75rem;
  max-width: 1.75rem;
  max-height: 1.75rem;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  z-index: 1000;
  justify-self: right;
}

.hamburger_button_burger {
  width: 1.25rem;
  height: 2px;
  background-color: var(--text-color);
  border-radius: 0.5rem;
  transition: all 0.3s ease-in-out;
}

.hamburger_button_burger::before,
.hamburger_button_burger::after {
  content: "";
  position: absolute;
  width: 1.25rem;
  height: 2px;
  background-color: var(--text-color);
  border-radius: 0.5rem;
  transition: all 0.3s ease-in-out;
}

.hamburger_button_burger::before {
  transform: translateY(-0.5rem);
}

.hamburger_button_burger::after {
  transform: translateY(0.5rem);
}
/* ANIMATION */
.hamburger_button.open .hamburger_button_burger {
  transform: translateX(-2rem);
  background: transparent;
}

.hamburger_button.open .hamburger_button_burger::before {
  transform: rotate(45deg) translate(1.25rem, -1.25rem);
}

.hamburger_button.open .hamburger_button_burger::after {
  transform: rotate(-45deg) translate(1.25rem, 1.25rem);
}

/* =============== BREAKPOINTS =============== */
/* For medium devices */
@media screen and (max-width: 768px) {
  .header {
    top: 0;
  }

  .nav {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: calc(var(--header-height) + 1.5rem);
  }

  .nav_menu {
    display: grid;
    place-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: var(--container-color);
    transition: 0.3s ease-out;
    z-index: 100;
    opacity: 0;
    visibility: hidden;
  }

  .hidden {
    opacity: 0;
  }

  .stycky-hidden {
    top: calc((var(--header-height) + 1.5rem + 1px) * -1);
  }

  .escape_hatch:hover .nav_logo_overlay {
    opacity: 0;
    cursor: pointer;
  }

  /* Show Menu */
  .show_menu {
    opacity: 1;
    visibility: visible;
    overflow: hidden;
  }

  .nav_list {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    justify-content: center;
    row-gap: 2rem;
  }

  .nav_link {
    font-size: var(--h2-font-size);
    font-weight: var(--font-medium);
  }

  .pc_cta {
    display: none;
  }

  .mobile_cta {
    display: inherit;
  }

  .hamburger_button {
    display: flex;
  }
}
