.infoSection {
  position: relative;
  background-color: var(--body-color);
  padding-bottom: 5rem;
}

.info_title {
  margin-top: 1rem;
  margin-bottom: 4rem;
  z-index: 1;
}

.info_container {
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
  place-items: center;
  z-index: 1;
  margin-bottom: 3rem;
}

.focusPoints_imgs {
  width: 100%;
  order: 1;
  padding-left: 2rem;
}

.focus_img1 {
  background-image: url("./homeFocusPoints/focusPointsImgs/vvo1.jpeg");
}

.focus_img2 {
  background-image: url("./homeFocusPoints/focusPointsImgs/vvo2.jpeg");
}

.focus_img3 {
  background-image: url("./homeFocusPoints/focusPointsImgs/vvo3.jpeg");
}

.focus_img4 {
  background-image: url("./homeFocusPoints/focusPointsImgs/vvo4.jpeg");
}

.services_imgs {
  width: 100%;
  order: 0;
  padding-right: 2rem;
}

.service_img1 {
  background-image: url("./homeServices/homeServicesImgs/Etturval1.jpeg");
}

.service_img2 {
  background-image: url("./homeServices/homeServicesImgs/Etturval2.jpeg");
}

.service_img3 {
  background-image: url("./homeServices/homeServicesImgs/Etturval3.jpeg");
}

.service_img4 {
  background-image: url("./homeServices/homeServicesImgs/Etturval4.jpeg");
}

.info_imgs {
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.info_img {
  aspect-ratio: 1/1.3;
  width: auto;
  background-color: var(--container-color);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.info_focusPoints {
  width: 100%;
  padding-right: 2rem;
}

.info_services {
  width: 100%;
  padding-left: 2rem;
}

.info_box {
  margin-bottom: var(--mb-1);
}

.info_focusPoints_grid,
.info_services_grid {
  grid-template-columns: repeat(1, 1fr);
  row-gap: 4rem;
}

.box {
  max-width: 15rem;
  text-align: center;
}

.link_div {
  display: grid;
  justify-content: center;
}

.to_services_link {
  color: var(--title-color);
  font-size: var(--normal-font-size);
  display: grid;
  grid-template-columns: max-content 1rem;
  column-gap: 0.25rem;
  cursor: pointer;
}

.link_line {
  width: 0;
  height: 1px;
  background-color: white;
  transition: 0.3s;
}

.link_arrow {
  font-size: var(--normal-font-size);
  transition: 0.3s;
}

.to_services_link:hover .link_line {
  width: 100%;
}

.to_services_link:hover .link_arrow {
  transform: translateX(00.25rem);
}

.span2 {
  grid-column: span 1;
}

.left {
  justify-self: start;
}

.mid {
  justify-self: center;
}

.right {
  justify-self: end;
}

.box_line {
  width: 100%;
  height: 1px;
  background-color: var(--text-color-dark);
  margin-top: var(--mb-0-75);
  margin-bottom: var(--mb-0-75);
}

.flower_container {
  place-items: center;
  gap: 0;
}

.bottom_title {
  z-index: 1;
}

.bottom_subtitle {
  z-index: 1;
  margin-bottom: 0;
}

.tmp {
  height: 1px;
  width: 100%;
  background-color: black;
}

.flower_div {
  display: grid;
  place-items: center;
  position: relative;
  width: 100%;
  height: 35rem;
}

.flower {
  aspect-ratio: 1/1;
  height: 25rem;
  stroke: #6ca48c;
}

.st2 {
  stroke-dasharray: 12750;
  stroke-dashoffset: 0;
}

.svg-hidden {
  stroke-dashoffset: 12750;
}

.flower_pot {
  z-index: 6;
  position: absolute;
  bottom: 0;
  background: url(../../assets//flower-pot.svg);
  background-repeat: no-repeat;
  background-position: center;
  aspect-ratio: 10/8;
  height: 8rem;
}

.flower_seed {
  z-index: 0;
  position: absolute;
  top: 0;
  background: url(../../assets//seed.svg);
  background-repeat: no-repeat;
  background-position: center;
  width: 3rem;
  height: 3rem;
}

.overlay {
  position: absolute;
  bottom: -2rem;
  width: 10rem;
  height: 5rem;
  z-index: 5;
  background-color: var(--body-color);
}

@media screen and (max-width: 992px) {
  .info_container {
    padding-left: var(--mb-1-5);
    padding-right: var(--mb-1-5);
  }
  .service {
    max-width: 14rem;
  }
}

/* For medium devices */
@media screen and (max-width: 768px) {
  .info_container {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 3rem;
    width: 100%;
    margin-bottom: 4rem;
  }

  .info_services_grid,
  .info_focusPoints_grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .info_focusPoints,
  .info_services {
    padding: 0;
    margin-bottom: 2rem;
  }

  .span2 {
    grid-column: span 2;
  }

  .left,
  .mid,
  .right {
    justify-self: center;
  }

  .tall .box_description {
    height: 3.75rem;
  }

  .fp-tall .box_description {
    min-height: 8.75rem;
  }

  .info_imgs {
    order: 1;
    padding-right: 0;
    grid-template-columns: repeat(4, 1fr);
  }

  .focusPoints_imgs {
    padding-left: 0;
  }

  .flower_div {
    width: 100%;
    height: 29rem;
  }

  .flower {
    height: 22rem;
  }

  .flower_pot {
    height: 6rem;
  }

  .flower_seed {
    display: none;
  }

  .overlay {
    display: none;
  }
}

@media screen and (max-width: 576px) {
  .info_container {
    width: 80%;
  }

  .info_focusPoints_grid,
  .info_services_grid {
    grid-template-columns: repeat(1, 1fr);
  }
  .span2 {
    grid-column: span 1;
  }

  .info_imgs {
    grid-template-columns: repeat(2, 1fr);
  }

  .tall .box_description {
    height: auto;
  }

  .fp-tall .box_description {
    height: auto;
  }
}
