.contact {
  background-color: var(--light-green-color);
  padding-bottom: 6rem;
}

.contact_title {
  color: var(--text-color-dark);
}

.contact_subtitle {
  color: var(--text-color-dark);
}

.contact_div {
  width: 100%;
}

.contact_container {
  grid-template-columns: repeat(2, 1fr);
}

.contact_company_name,
.contact_name,
.contact_email,
.contact_phone,
.contact_adress {
  color: var(--text-color-dark);
}

.contact_company_name {
  font-size: var(--h3-font-size);
  margin-bottom: var(--mb-1);
}

.contact_name {
  font-weight: var(--font-medium);
}

.contact_email {
  font-size: var(--normal-font-size);
}

.contact_information {
  margin-bottom: var(--mb-1);
}

.contact_social_title {
  font-size: var(--h3-font-size);
  color: var(--text-color-dark);
  margin-bottom: var(--mb-1);
}

.contact_social_media {
  display: flex;
  column-gap: 1.25rem;
}

.social_media {
  font-size: var(--h2-font-size);
  color: var(--text-color-dark);
  transition: 0.3s;
}

.social_media:hover {
  color: var(--highlight-color);
}

@media screen and (max-width: 768px) {
  .contact_container {
    grid-template-columns: repeat(1, 1fr);
    place-items: center;
  }
  .contact_div {
    width: 100%;
  }
  .contact_content {
    width: 60%;
  }
}

@media screen and (max-width: 576px) {
  .contact_content {
    width: 100%;
  }
}
