.contact_form_title {
  color: var(--text-color-dark);
}

.contact_form {
  display: grid;
  width: 100%;
  margin-top: var(--mb-1);
}

.input_box {
  position: relative;
}

.input_box input,
.input_box textarea {
  background-color: transparent;
  width: 100%;
  border: none;
  border-radius: 0px;
  border-bottom: 2px solid var(--text-color-dark);
  outline: none;
  font-size: var(--normal-font-size);
  color: var(--text-color-dark);
  padding: 0.5rem 0;
  margin: var(--mb-0-75) 0;
  resize: none;
}

.input_box textarea {
  height: 8rem;
}

.input_box label {
  position: absolute;
  left: 0;
  color: var(--text-color-dark);
  padding: 0.5rem 0;
  margin: var(--mb-0-5) 0;
  font-size: var(--normal-font-size);
  pointer-events: none;
  transition: 0.3s;
}

.input_box input:focus ~ label,
.input_box input:valid ~ label,
.input_box textarea:focus ~ label,
.input_box textarea:valid ~ label {
  font-size: var(--small-font-size);
  transform: translateY(-1.5rem);
}

.input_button input {
  font-size: var(--small-font-size);
  color: var(--title-color);
  font-weight: var(---font-medium);
  background: #6ca48c;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 5rem;
  transition: 0.3s;
}

.input_button input:hover {
  background-color: var(--highlight-color);
  cursor: pointer;
}
