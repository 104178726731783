.footer {
  position: relative;
}

.footer_container {
  padding: 4rem 0 4rem;
  place-items: center;
}

.footer_title {
  font-size: var(--h2-font-size);
  text-align: center;
  margin-bottom: var(--mb-2);
}

.footer_title,
.footer_link {
  color: var(--title-color);
}

.footer_link {
  font-size: var(--medium-font-size);
  color: var(--title-color);
  font-weight: var(---font-medium);
  transition: 0.3s;
  background: linear-gradient(295deg, transparent 45%, var(--text-color) 55%);
  background-size: 250% 110%;
  background-position: 100%;
  max-width: 300px;
  max-height: 100px;
  padding: 0.25rem 0.5rem;
}

.footer_link:hover {
  color: var(--text-color-dark);
  background-position: 0%;
}

.footer_list {
  display: flex;
  justify-content: center;
  column-gap: 1.5rem;
  margin-bottom: var(--mb-2);
}

.footer_social {
  display: flex;
  justify-content: center;
  column-gap: 1.25rem;
  margin-bottom: 3rem;
}

.footer_social_link {
  font-size: var(--h1-font-size);
  color: #6ca48c;
  transition: 0.3s;
}

.footer_social_link:hover {
  color: var(--highlight-color);
}

.footer_logo {
  background: url(../../assets//logo.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 10rem;
  aspect-ratio: 1/1;
  width: 7rem;
}

/* =============== BREAKPOINTS =============== */
/* For large devices */
@media screen and (max-width: 992px) {
  .footer_social_link {
    padding: 0.25rem;
    border-radius: 0.25rem;
  }

  .footer_logo {
    width: 6rem;
  }
}
