.about {
  background-color: var(--body-color);
  padding: 8rem 0;
}

.about_container {
  grid-template-columns: repeat(2, 1fr);
  column-gap: 2rem;
}

.about_imgs {
  grid-template-columns: repeat(2, 1fr);
}

.about_img {
  justify-self: center;
  width: 15rem;
}

.about_text {
  margin-bottom: 0.5rem;
}

.title {
  font-size: var(--h3-font-size);
  font-weight: var(--font-medium);
}

@media screen and (max-width: 768px) {
  .about_container {
    place-items: center;
    grid-template-columns: repeat(1, 1fr);
    row-gap: 4rem;
  }

  .about_description {
    width: 80%;
  }
}
