.service_descriptions {
  background-color: var(--body-color);
  padding-top: 8rem;
  padding-bottom: 8rem;
}

.services_title {
  margin-top: 1rem;
  margin-bottom: 4rem;
}

.services_container {
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  row-gap: 3rem;
  margin-bottom: 4rem;
}

.service_box {
  overflow: hidden;
  position: relative;
  transition: 0.3s;
}

.closed {
  min-height: 21rem;
  max-height: 21rem;
}

.open {
  min-height: 21rem;
  max-height: 65rem;
}

.service_title {
  text-align: center;
  margin-bottom: var(--mb-0-5);
}

.service_time {
  text-align: center;
}

.service_line {
  width: 100%;
  height: 1px;
  background-color: var(--text-color-dark);
  margin-top: var(--mb-0-75);
  margin-bottom: var(--mb-0-75);
}

.service_img_div {
  width: 100%;
  height: 12rem;
  display: grid;
  place-items: center;
}

.service_img {
  aspect-ratio: 1/1.2;
  width: 10rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.KonsultationImg {
  background-image: url("./images/konsultation.jpg");
}

.SkotselImg {
  background-image: url("./images/skotsel.jpg");
}

.AnlaggningImg {
  background-image: url("./images/anlaggning.jpg");
}

.FormgivningImg {
  background-image: url("./images/formgivning.jpg");
}

.StylingImg {
  background-image: url("./images/styling.jpg");
}

.BeskarningImg {
  background-image: url("./images/beskarning.jpg");
}

.service_description {
  margin-top: var(--mb-1);
  display: grid;
  gap: 0.5rem;
}

.button_div {
  display: grid;
  justify-content: center;
  padding: 1rem 0;
}

.no_button {
  display: none;
}

.show_more {
  background-color: transparent;
  color: var(--text-color);
  display: grid;
  grid-template-columns: max-content 1rem;
  column-gap: 0.25rem;
}

.uil-arrow-down {
  width: 1rem;
  height: 1rem;
}

.arrow {
  transition: 0.3s;
  transform: rotate(0deg);
  font-size: var(--normal-font-size);
  transform-origin: center;
}

.rotated {
  transform: rotate(180deg);
}

.button_line {
  width: 0;
  height: 1px;
  background-color: var(--text-color);
  transition: 0.3s;
}

.show_more:hover .button_line {
  width: 100%;
}

.courses_container {
  place-items: center;
}

.courses_wrapper {
  place-items: center;
  width: 50%;
}

@media screen and (max-width: 768px) {
  .services_container {
    grid-template-columns: repeat(2, 1fr);
  }
  .closed {
    min-height: 20rem;
    max-height: 20rem;
  }

  .open {
    min-height: 20rem;
    max-height: 60rem;
  }

  .show_more:hover .button_line {
    width: 0;
  }

  .courses_wrapper {
    width: 25rem;
  }
}

@media screen and (max-width: 576px) {
  .services_container {
    grid-template-columns: repeat(1, 1fr);
  }
  .closed {
    min-height: calc(8rem + 15rem * 1.2);
    max-height: calc(8rem + 15rem * 1.2);
  }

  .open {
    min-height: calc(8rem + 15rem * 1.2);
    max-height: calc(35rem + 15rem * 1.2);
  }

  .service_img_div {
    height: calc(15rem * 1.2);
  }

  .service_img {
    width: 15rem;
  }

  .courses_wrapper {
    width: 100%;
  }
}
